import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private mateService: Meta, private titleServer: Title) {
    let title = '网销供应链服务通';
    let keywords = '合理避税,避税,企业避税,个税,个人所得税,税收优惠政策,税务咨询';
    let description = '免费税务筹划服务平台，让您合法纳税的同时享受利益最大化。提供税收筹划，个人独资企业设立、个人所得税筹划、企业所得税税收优惠、个税筹划、税务咨询、薪资避税和股权转让合理避税，帮助企业寻找避税天堂等服务';

    this.titleServer.setTitle(title);
    this.mateService.addTags([
      {name: 'keywords', content: keywords},
      {name: 'description', content: description},
    ]);
  }

  
  
  
}
