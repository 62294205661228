import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  id: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');

      let background = '';
      let btnClass = 'btn-primary';
      switch (this.id) {
        case "1":
          background = '#337ab7';
          btnClass = 'btn-primary';
          break;
        case "2":
          background = '#f0ad4e';
          btnClass = 'btn-warning';
          break;
        case "3":
          background = '#5bc0de';
          btnClass = 'btn-info';
          break;
        case "4":
          background = '#5cb85c';
          btnClass = 'btn-success';
          break;
      }

      $('body').css('background', background);
      $('#submit').addClass(btnClass);
      $('.text-center').css('color', '#fff');
    });
  }

}
